.candidati{
    @media screen and (max-width: 500px) {
        display: none;
    }
}

.namesContainer{

    > img{
        padding-left: 20px;
    }
    @media screen and (max-width: 500px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        > img{
            padding-left: 0px;
        }
    }
}

.names{
    padding-left: 20px;
    @media screen and (max-width: 500px) {
        font-size: 12px;
        text-align: center;
        padding-left: 0px;
    }
}

.logo{
    @media screen and (max-width: 500px) {
       max-width: 50%;
    }
}

.removedUser{
   display: none;
}